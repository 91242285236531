// Here you can add other styles
.sidebar-logo {
  padding: 0.5rem 2rem;
}

.login-logo {
  margin: 0 auto;
  width: 200px;
}

@media print {
  .order-status-select {
    display: none;
  }
}

.chips {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  .chip {
    box-sizing: border-box;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background: rgba(0, 0, 0, 0.08);
    height: 32px;
    span {
      margin-right: 5px;
      padding: 0 12px;
    }
    button {
      font-size: 0.5rem;
      border: none;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      background: rgba(0, 0, 0, 0.16);
      margin: 0 10px 0 -6px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
